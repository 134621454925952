import React from "react";

export default ({ color }) => (
  <svg width="18" height="18" viewBox="0 0 18 18">
    <g clipPath="url(#clicp-shop-bee)">
      <path
        d="M11.9261 4.57958C11.8279 4.5794 11.7355 4.53308 11.6765 4.45455C11.6176 4.37598 11.599 4.27433 11.6263 4.17999L12.4753 1.26475C12.5014 1.15758 12.4839 1.04443 12.4266 0.950166C12.3719 0.862619 12.2839 0.801059 12.1829 0.779632L11.166 0.620979C11.0557 0.603865 10.963 0.529188 10.9227 0.425057C10.8825 0.320926 10.9009 0.203253 10.9711 0.116448C11.0412 0.0296424 11.1524 -0.0131061 11.2627 0.0043257L12.2989 0.166621C12.5717 0.220932 12.8099 0.385491 12.9573 0.621403C13.1052 0.862477 13.1488 1.15334 13.078 1.42719L12.2256 4.35463C12.1868 4.48779 12.0648 4.57944 11.9261 4.57958Z"
        fill="#2C2F38"
      />
      <path
        d="M17.6861 7.41631C17.5325 7.4161 17.4019 7.30419 17.3782 7.1525L17.2159 6.11628C17.1915 6.02265 17.1315 5.94228 17.0487 5.89225C16.9512 5.83394 16.834 5.81835 16.7246 5.84911L13.8219 6.69238C13.7148 6.7236 13.5992 6.69521 13.5186 6.61798C13.4381 6.54076 13.4049 6.42641 13.4316 6.31804C13.4582 6.2097 13.5407 6.12381 13.6479 6.0928L16.5601 5.24692C16.8359 5.17118 17.1308 5.21213 17.3755 5.36018C17.6077 5.50263 17.7715 5.73384 17.8289 6.00013L17.9949 7.05586C18.0089 7.14603 17.9828 7.23785 17.9234 7.30716C17.8641 7.37649 17.7774 7.41634 17.6861 7.41631Z"
        fill="#2C2F38"
      />
      <path
        d="M17.2144 14.5645C17.1769 15.8005 16.4309 16.7057 15.4571 17.2956C14.6143 17.8601 13.5581 18.0041 12.5949 17.6857C12.3258 17.8061 12.0427 17.8921 11.7521 17.9417C9.53599 18.3412 8.50907 16.612 8.08459 15.4884H8.08148C7.73108 15.7644 7.36186 16.0158 6.97653 16.2406C6.26656 16.6602 5.48931 16.9537 4.67925 17.1083C3.46192 17.3268 2.36012 17.0989 1.63286 16.3717C0.905607 15.6444 0.677757 14.5457 0.896237 13.3253C1.05206 12.5136 1.34777 11.7353 1.77019 11.0249C1.99203 10.6385 2.24244 10.2691 2.5193 9.91996C1.39872 9.49548 -0.339826 8.46856 0.0596899 6.24617C0.109333 5.95662 0.195325 5.67453 0.31565 5.40655V5.40344C-0.00310689 4.44027 0.140873 3.3839 0.705796 2.5412C1.29261 1.56424 2.2009 0.81825 3.43381 0.780769C3.69483 0.777693 3.95539 0.802798 4.21103 0.855694C6.4022 1.30517 7.97845 3.29968 8.77437 5.35351C8.91891 5.04741 9.11533 4.76861 9.35492 4.52948C9.83247 4.05193 10.7314 3.88337 11.6085 3.85215C12.2117 3.83942 12.815 3.87489 13.4126 3.95826C13.7459 3.98673 14.0153 4.24187 14.0618 4.57315C14.1373 5.17767 14.1676 5.78697 14.1523 6.39599C14.118 7.27309 13.9494 8.17201 13.4719 8.64956C13.2348 8.88859 12.9568 9.08313 12.651 9.22389C14.7017 10.0261 16.693 11.6023 17.1456 13.7904C17.1964 14.0452 17.2194 14.3047 17.2144 14.5645Z"
        fill="#D1D4D1"
      />
      <path
        d="M14.1527 6.39673C14.1184 7.27383 13.9498 8.17275 13.4722 8.6503C13.2352 8.88933 12.9571 9.08387 12.6513 9.22463C12.5327 9.17782 12.411 9.13411 12.2924 9.09352C11.9971 8.98932 11.6959 8.9028 11.3903 8.83445C11.2093 7.69209 10.3134 6.79618 9.17106 6.61521H9.16795C9.0996 6.30964 9.01308 6.00842 8.90888 5.71314L8.90577 5.71003C8.86518 5.59144 8.82151 5.47592 8.77466 5.35733V5.35422C8.9192 5.04812 9.11562 4.76932 9.35521 4.53019C9.83276 4.05264 10.7317 3.88409 11.6088 3.85286C12.212 3.84013 12.8153 3.8756 13.4129 3.95897C13.7462 3.98744 14.0156 4.24259 14.0621 4.57386C14.1377 5.17838 14.1679 5.78772 14.1527 6.39673Z"
        fill="#FFB000"
      />
      <path
        d="M9.4396 8.55981C9.173 8.91333 8.85894 9.22841 8.50635 9.49621C8.77203 9.14189 9.08619 8.82666 9.4396 8.55981Z"
        fill="#BDC3C7"
      />
      <path
        d="M17.2147 14.5648C17.1773 15.8009 16.4313 16.7061 15.4575 17.296C14.6146 17.8605 13.5584 18.0044 12.5952 17.6861C13.2557 17.408 13.7743 16.8726 14.031 16.2035C14.1162 15.9832 14.1759 15.7538 14.209 15.5199C14.6085 12.8606 12.4579 10.2044 10.4697 9.04011C10.1985 8.85982 9.91361 8.7012 9.61755 8.56567C9.9751 8.58275 10.3309 8.62656 10.6819 8.69678C10.9129 8.73112 11.1501 8.77482 11.3905 8.83412C11.696 8.90246 11.9973 8.98899 12.2925 9.09319C12.4111 9.13378 12.5329 9.17745 12.6515 9.2243C14.7022 10.0265 16.6936 11.6027 17.1462 13.7908C17.1968 14.0455 17.2198 14.3051 17.2147 14.5648Z"
        fill="#D1D4D1"
      />
      <path
        d="M14.2089 15.5199C14.1759 15.7538 14.1162 15.9831 14.031 16.2034C13.7742 16.8725 13.2557 17.408 12.5952 17.686C12.3261 17.8064 12.043 17.8924 11.7525 17.942C9.53634 18.3415 8.50943 16.6123 8.08495 15.4887C7.97106 15.1982 7.88227 14.8985 7.81965 14.5929C7.81965 14.5929 6.84581 11.7181 8.50009 9.502L8.50635 9.49574C8.85898 9.22794 9.173 8.91286 9.4396 8.55934C9.44586 8.55623 9.44897 8.54997 9.45519 8.54686C9.48016 8.52812 9.53634 8.53749 9.60816 8.5656C9.61127 8.56249 9.61127 8.5656 9.61441 8.5656H9.61753C9.91358 8.70113 10.1985 8.85975 10.4696 9.04004C12.4579 10.2043 14.6084 12.8605 14.2089 15.5199Z"
        fill="#BDC3C7"
      />
      <path
        d="M9.4396 8.55981C9.173 8.91333 8.85894 9.22841 8.50635 9.49621C8.77203 9.14189 9.08619 8.82666 9.4396 8.55981Z"
        fill="#BDC3C7"
      />
      <path
        d="M2.40786 16.6634C1.52351 16.831 0.71309 17.2691 0.088623 17.9174C0.73678 17.2927 1.17487 16.4821 1.34237 15.5977L2.40786 16.6634Z"
        fill="#2C2F38"
      />
      <path
        d="M6.97689 16.238V16.2411C6.26693 16.6607 5.48968 16.9542 4.67962 17.1088C4.55968 16.1475 4.1212 15.2541 3.43422 14.5712C2.75208 13.883 1.85829 13.4444 0.896606 13.3258C1.05243 12.5141 1.34813 11.7357 1.77056 11.0254C3.08396 11.2164 4.29979 11.8287 5.2352 12.7702C6.17772 13.7058 6.78921 14.9233 6.97689 16.238Z"
        fill="#2C2F38"
      />
      <path
        d="M4.67972 17.1082C3.46239 17.3267 2.36059 17.0988 1.63333 16.3716C0.906079 15.6443 0.678229 14.5456 0.896709 13.3252C1.85839 13.4438 2.75218 13.8824 3.43432 14.5706C4.12134 15.2536 4.55978 16.1469 4.67972 17.1082Z"
        fill="#FFB000"
      />
      <path
        d="M9.4517 8.54329C9.44859 8.54955 9.44233 8.55266 9.43922 8.55888C9.08581 8.82573 8.77162 9.14095 8.50597 9.49528L8.49971 9.50154C6.28669 11.1558 3.40885 10.182 3.40885 10.182C3.10555 10.1195 2.80797 10.0318 2.51927 9.9198C1.39872 9.49532 -0.339827 8.4684 0.05969 6.24601C0.109333 5.95646 0.195325 5.67437 0.315651 5.40639V5.40328C0.59562 4.74405 1.13042 4.22615 1.79827 3.96747C2.01859 3.88229 2.24796 3.82261 2.48182 3.78955C5.14118 3.39003 7.79742 5.54061 8.95852 7.52885C9.13882 7.79998 9.29743 8.0849 9.43296 8.38096V8.38722C9.46107 8.46214 9.47044 8.51833 9.4517 8.54329Z"
        fill="#BDC3C7"
      />
      <path
        d="M9.43331 8.38115C9.29778 8.0851 9.13916 7.80018 8.95887 7.52905C7.79773 5.54077 5.14149 3.39019 2.48214 3.78971C2.24824 3.82277 2.0189 3.88245 1.79859 3.96763C1.13073 4.22632 0.595936 4.74421 0.315967 5.40344C-0.00279 4.44027 0.14119 3.3839 0.706113 2.5412C1.29292 1.56424 2.20121 0.81825 3.43413 0.780769C3.69515 0.777693 3.9557 0.802798 4.21135 0.855694C6.40251 1.30517 7.97876 3.29968 8.77468 5.35351V5.35662C8.8215 5.47521 8.8652 5.59073 8.90579 5.70932L8.9089 5.71243C9.01311 6.00771 9.09963 6.30893 9.16798 6.6145C9.22536 6.84599 9.2702 7.08046 9.3022 7.31679C9.37532 7.66734 9.41916 8.02336 9.43331 8.38115Z"
        fill="#D1D4D1"
      />
      <path
        d="M11.3906 8.83378C11.1503 8.77448 10.9131 8.73078 10.6821 8.69645C10.3311 8.62626 9.97527 8.58245 9.61773 8.56534H9.61461C9.6115 8.56534 9.6115 8.56223 9.60836 8.56534C9.53658 8.53726 9.48039 8.52789 9.45539 8.5466C9.44914 8.54971 9.44603 8.55597 9.4398 8.55908C9.44291 8.55282 9.44917 8.54971 9.45228 8.54349C9.47102 8.51852 9.46165 8.46234 9.43354 8.38741V8.38115C9.4194 8.02336 9.37556 7.66734 9.30243 7.31679C9.27043 7.08046 9.2256 6.84599 9.16821 6.6145H9.17132C10.3137 6.7955 11.2096 7.69145 11.3906 8.83378Z"
        fill="#2C2F38"
      />
      <path
        d="M7.81961 14.593C7.88227 14.8987 7.97102 15.1984 8.08491 15.4889H8.0818C7.73139 15.7649 7.36218 16.0163 6.97684 16.2411V16.238C6.78912 14.9233 6.17763 13.7058 5.23515 12.7702C4.29974 11.8287 3.08387 11.2164 1.77051 11.0254C1.99235 10.639 2.24276 10.2696 2.51961 9.92045C2.80832 10.0325 3.10586 10.1202 3.4092 10.1826C3.4092 10.1826 6.28703 11.1565 8.50005 9.5022C6.84577 11.7183 7.81961 14.593 7.81961 14.593Z"
        fill="#FFB000"
      />
    </g>
    <defs>
      <clipPath id="clicp-shop-bee">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
